/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"

import "./filter.css"

const Filter = ({ fields, filters, setFilters }) => {

  const toggleFilter = (field) => {
    const val = new Set(filters);
    if (val.has(field)) {
      val.delete(field);
    } else {
      val.add(field);
    }
    setFilters(val);
  }

  var field_chips = [];

  for (const field of fields) {
    field_chips.push(
      <div
        className={filters.has(field) ? "filter-item selected" : "filter-item"}
        key={field}
        onClick={() => toggleFilter(field)}
      >
        {field}
      </div>
    );
  }

  return (
    <div className="filter-component">
      <div className="filter-name-container">
        Filters:<br/>
        <span className="clear-filters" onClick={() => setFilters(new Set())}>[clear]</span>
      </div>
      <div className="filter-item-container">
        {field_chips}
      </div>
    </div>
  )
}

export default Filter
