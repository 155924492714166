import * as React from "react"
import { connect } from "react-redux"
import { graphql, Link } from "gatsby"

import ArticleList from "../components/articleList"
import Filter from "../components/filter"
import Layout from "../components/layout"
import Seo from "../components/seo"


export const query = graphql`query BlogList{
  allMdx(
    sort: { fields: [frontmatter___date], order:DESC }
    filter: {fileAbsolutePath: {regex: "/\/src\/blog\//"}}
  ) {
    edges{
      node{
        frontmatter {
          title
          slug
          date(formatString: "MMMM DD, YYYY")
          categories
        }
        excerpt
      }
    }
    distinct(field: frontmatter___categories)
  }
}`
        

const BlogPage = ({data, articleFilters, setArticleFilters}) => {
  const { allMdx } = data;
  const posts = allMdx.edges;
  const categories = allMdx.distinct;

  const title="Knockout Cats"
  const subtitle="Blog"

  return (
    <Layout title={title} subtitle={subtitle}>
      <Seo title="Blog" />
      <p className="center">
        This space contains the documented musings of the creator of <Link to="/">Knockout Cats</Link>. I hope you enjoy!
      </p>

      <Filter fields={categories} filters={articleFilters} setFilters={setArticleFilters} />

      <ArticleList posts={posts} />

    </Layout>
  )
}

const mapStateToProps = ({articleFilters}) => {
  return { articleFilters };
}

const setArticleFilters = articleFilters => ({
  type: 'SET_STATE_VALUE',
  payload: { articleFilters: articleFilters }
});

const mapDispatchToProps = dispatch => {
  return {
    setArticleFilters: async filters => {
      try {
        dispatch(setArticleFilters(filters));
      } catch(err) {
        console.log(err);
      }
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(BlogPage)
