import * as React from "react"
import { connect } from 'react-redux';
import { Link } from "gatsby"

import "./articleList.css"
       
const ArticleList = ({posts, articleFilters}) => {

  return (
      <div className="article-list">
        {posts.filter( ({node}) => (
            articleFilters.size === 0 ||
            node.frontmatter.categories.filter(x => articleFilters.has(x)).length > 0
          )).map( ({node}, index) => (
            <Link key={index} to={node.frontmatter.slug}>
              <div className="article-list-item">
                <div className="article-header">
                  <div className="article-title">{node.frontmatter.title}</div>
                  <div className="article-date">{node.frontmatter.date}</div>
                </div>
                <div className="article-excerpt">{node.excerpt}</div>
              </div>
            </Link>
          )
        )}
      </div>
  )
}

const mapStateToProps = ({articleFilters}) => {
  return { articleFilters };
}

export default connect(mapStateToProps)(ArticleList)
